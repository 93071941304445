const it = {
  welcome_to_agami: 'Benvenut* su Agami Route',
  login: 'ACCEDI',
  send_email: 'INVIA EMAIL',
  login_with_password: 'Accedi con password',
  login_with_email: 'Accedi solo con email',
  lang: 'Italiano',
  select_lang: 'Seleziona la tua lingua',
  day: 'Giorno',
  night: 'Notte',
  confirm: 'Conferma',
  continue: 'Continua',
  cancel: 'Annulla',
  refresh: 'Ricarica',
  yes_continue: 'Sì, continua',
  start: 'Inizia',
  back: 'Indietro',
  wait: 'Attendere prego...',
  headset: 'Utilizza le cuffie fornite per un\'esperienza migliore',
  raise_volume: 'Aumenta il volume del dispositivo',
  sound: 'Senti il suono correttamente?',
  wait_location: 'Controllo dell\'accuratezza della posizione',
  gps: 'L\'app sta cercando di ottenere la tua posizione',
  gps_error: 'C\'è un problema con il GPS di questo telefono',
  gps_quality_bad: 'Precisione della posizione scarsa',
  gps_quality_bad_details: 'Potresti riscontrare problemi con la riproduzione automatica',
  gps_quality_average: 'Precisione della posizione accettabile',
  gps_quality_good: 'Precisione della posizione ottima',
  cookies: 'C\'è già un tour in corso',
  cookies_action: 'Desideri continuare o ricominciare?',
  cookies_delete: 'Cambia esperienza',
  cookies_maintain: 'Continua esperienza',
  unlocking: 'Se hai bisogno di utilizzare l\'app durante la visita, sbloccala utilizzando questo codice',
  unlock: 'Conferma lo sblocco?',
  unlock_subtitle: 'Lo sblocco dello schermo è solo per gli amministratori',
  areyouready: 'Pront* per iniziare?',
  welcome: 'BENVENUT*',
  map: 'Mappa',
  code: 'Codice manuale',
  insert_code: 'Inserisci un codice manuale e tocca il pulsante "Riproduci"',
  faq: [
    {
      q: 'Problemi di connessione?',
      a: 'Se riscontri problemi di connessione, prova a...',
    },
    {
      q: 'Altre domande frequenti',
      a: 'Se riscontri problemi di connessione, prova a...',
    },
  ],
  mobile_only: 'Questa app è stata progettata solo per dispositivi mobili.',
  screen_locked: 'Schermo bloccato',
  swipe_left: 'Scorri a sinistra per sbloccare',
  admin_title: 'Controlli amministratore',
  admin_close: 'Chiudi',
  admin_test: 'Test audio',
  admin_reset_soft: 'Cambia esperienza',
  admin_reset_hard: 'Reset dell\'app',
  admin_reset_hard_confirm: 'Vuoi resettare l\'app?',
  admin_reset_hard_desc: 'Il reset dell\'app cancellerà tutte le esperienze salvate e i loro contenuti. Dovrai scaricarli nuovamente.',
  admin_logout: 'Logout',
  admin_logout_confirm: 'Logout?',
  admin_logout_desc: 'Dovrai scansionare il QR o inserire le credenziali per accedere di nuovo.',
  search_gps: 'In attesa della copertura GPS',
  select_experience: 'Seleziona un\'esperienza',
  no_experience: 'Sembra che tu non abbia nessuna esperienza disponibile.',
  pause: 'Pausa',
  stop: 'Stop',
  stop_all: 'Stoppa tutto',
  restart: 'Riprendi',
  beforestart: 'Tocca il pulsante "Inizia" quando sei pront* ad iniziare l\'esperienza.',
  ispause: 'ESPERIENZA IN PAUSA',
  ispause_restart: 'Fai clic sul pulsante "Riproduci" per riprenderla.',
  play: 'Riproduci',
  offline_experience: 'Esperienza offline',
  replay_title: 'Il contenuto è disponibile per essere riprodotto',
  replay_message: 'C\'è un contenuto disponibile che hai già ascoltato. Vuoi riprodurlo?',
  redeem_exp_title: 'Riscatta esperienza',
  redeem_exp_result_title: 'Esperienza riscattata',
  redeem_exp_button: 'RISCATTA',
  go_to_exp_button: 'INIZIA',
  check_email_login: 'Controlla la tua email per confermare l\'accesso.',
  redeemed_until_days: 'Hai accesso per i prossimi',
  redeemed_until_date: 'Hai accesso fino al',
  alreadly_redeemed: 'Sembra che tu abbia già utilizzato questo codice in precedenza. Dovresti già avere questa esperienza.',
  redeem_ok: 'L\'esperienza è stata creata con successo per te.',

  gps_location_unavailable: 'Servizi di localizzazione non disponibili',
  gps_location_unavailable_desc: 'I servizi di localizzazione sono disabilitati o non è stato concesso l\'accesso. Si prega di seguire le istruzioni su come controllare le impostazioni corrette.',
  gps_location_open_phone_settings: 'Apri le impostazioni del telefono',
  gps_location_browser_in_use: 'il browser che stai utilizzando',

  gps_location_android_1: 'Vai su "Posizione"',
  gps_location_android_2: 'Verifica che "Utilizza posizione" sia abilitato',
  gps_location_android_3: 'Apri "Autorizzazioni posizione app"',
  gps_location_android_4: 'Scorri fino in fondo nella sezione "Non consentite" e controlla se',
  gps_location_android_5: 'è presente',
  gps_location_android_6: 'Se è il caso, clicca su di esso e seleziona "Chiedi sempre" o "Consenti solo quando si utilizza l\'app"',

  gps_location_ios_1: 'Vai su "Privacy e sicurezza" > "Servizi di localizzazione"',
  gps_location_ios_2: 'Verifica che il servizio di localizzazione sia attivo',
  gps_location_ios_2_1_chrome: 'Verifica anche che il servizio sia attivo per Chrome nell\'elenco sottostante',
  gps_location_ios_2_1_safari: 'Verifica anche che il servizio sia attivo per Safari nell\'elenco sottostante',
  gps_location_ios_3: 'Torna alle impostazioni del telefono',
  gps_location_ios_4: 'Scorri verso il basso e cerca le impostazioni di',
  gps_location_ios_5: 'All\'interno delle impostazioni, cerca "Posizione"',
  gps_location_ios_6: 'Impostalo su "Chiedi" o "Consenti"',

  gps_location_chrome_1: 'Apri Chrome',
  gps_location_chrome_2: 'Vai a',
  gps_location_chrome_3: 'Apri le impostazioni del sito cliccando sull\'icona appena a sinistra dell\'indirizzo del sito web',
  gps_location_chrome_4: 'Seleziona "Autorizzazioni"',
  gps_location_chrome_5: 'Verifica che "Posizione" sia consentita',

  gps_location_safari_1: 'Apri Safari',
  gps_location_safari_2: 'Vai a',
  gps_location_safari_3: 'Apri le impostazioni del sito cliccando sull\'icona "Aa" appena a sinistra dell\'indirizzo del sito web',
  gps_location_safari_4: 'Seleziona "Impostazioni del sito web"',
  gps_location_safari_5: 'Nella sezione "Consenti", verifica che "Posizione" sia impostata su "Chiedi" o "Consenti"',
  gps_location_safari_6: 'Clicca su "Fatto"',

  gps_location_browser_1: 'Apri il browser preferito',
  gps_location_browser_2: 'Vai a',
  gps_location_browser_3: 'Apri le impostazioni del sito web',
  gps_location_browser_4: 'Verifica che il permesso di accesso alla posizione sia impostato su "Chiedi" o "Consenti"',

  go_without_gps: 'Procedi senza posizione',
  go_without_gps_warning: 'Sarai in grado di riprodurre solo il contenuto inserendo manualmente i codici.',

  read_instructions: 'Leggi le istruzioni',

  go_home: 'Vai alla home',

  multiple_agami_active: 'più agami attivi',
};

export default it;

import packageJson from '../../../package.json';

const VERSION = packageJson.version;

const divStyle = {
  opacity: 0.6,
  fontSize: '13px',
};

export default function AppVersion() {
  return (
    <div style={divStyle}>
      <em>
        v
        {VERSION}
      </em>
    </div>
  );
}

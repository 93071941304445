import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import {
  Stack,
  Button,
  CircularProgress,
  Backdrop,
  Tabs,
  Tab,
  Box,
  Typography,
} from '@material-ui/core';
import BackIcon from '@material-ui/icons/ArrowRightAlt';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import { CloudDoneOutlined, Refresh } from '@material-ui/icons';
import String from './String';
import { useExperience } from '../contexts/experience';
import SetupStep from './SetupStep';
import { useSession } from '../contexts/session';
import Map from './Map';
import { MAPBOX_DARK_MAP_STYLE, MAPBOX_LIGHT_MAP_STYLE } from '../../config/map';
import { configHasGeoSpots, getPositionsFromConfig } from '../utils/geoXp';
import Layout from './Layout';
import Header from './Header';
import Footer from './Footer';
import ThemeSwitcher from './ThemeSwitcher';
import { AUDIO_PRECACHE_NAME } from '../../config/precache';
import useToast from '../hooks/useToast';
import { useLanguage } from '../contexts/language';

async function downloadAudio(config) {
  if (!config?.audio?.sounds || config.audio.sounds.length === 0) return;
  try {
    const audioUrls = config.audio?.sounds?.map((audio) => audio.url) || [];
    const cache = await caches.open(AUDIO_PRECACHE_NAME);
    await Promise.all(audioUrls.map((url) => cache.delete(url, {
      ignoreVary: true, ignoreSearch: true,
    })));
    await cache.addAll(audioUrls);
  } catch (e) {
    console.error('Error caching audio files', e);
  }
}

function DownloadMapTilesHiddenOverlay({ experience, loaded }) {
  const [lightLoaded, setLightLoaded] = useState(false);
  const [darkLoaded, setDarkLoaded] = useState(false);

  const positions = useMemo(() => (
    experience?.config?.geo ? getPositionsFromConfig(experience.config.geo) : []), [experience]);

  useEffect(() => {
    if (lightLoaded && darkLoaded && loaded) loaded();
  }, [lightLoaded, darkLoaded, loaded]);

  return (
    <Backdrop style={{ position: 'fixed', opacity: 0, pointerEvents: 'none' }} open>
      <Layout
        fullscreen
        top={<Header />}
        bottom={(
          <Footer>
            <ThemeSwitcher />
          </Footer>
        )}
      >
        <Tabs value="map" aria-label="tabs" style={{ width: '100%', display: 'flex' }}>
          <Tab label="Map" value="map" style={{ flex: 1 }} />
        </Tabs>
        <Layout>
          <Box width="100%" height="100%" position="relative">
            <Box width="100%" height="100%" position="absolute">
              <Map
                positions={positions}
                styleOverride={MAPBOX_LIGHT_MAP_STYLE}
                loaded={() => setLightLoaded(true)}
              />
            </Box>
            <Box width="100%" height="100%" position="absolute">
              <Map
                positions={positions}
                styleOverride={MAPBOX_DARK_MAP_STYLE}
                loaded={() => setDarkLoaded(true)}
              />
            </Box>
          </Box>
        </Layout>
      </Layout>
    </Backdrop>
  );
}

export function SetupExperiences(p) {
  const {
    experiences,
    saveExperience,
    selectedExperience,
    selectExperience,
    offline,
    offlineMode,
    user,
  } = useSession();
  const { loadConfig, config } = useExperience();
  const { setLanguage, availableLanguages } = useLanguage();
  const history = useHistory();
  const [loading, setLoading] = useState(null);
  const [downloadMapTiles, setDownloadMapTiles] = useState(null);
  const toast = useToast();

  const handleSelect = useCallback(async (experience) => {
    setLoading(experience.id);
    const selected = await selectExperience(experience);
    if (selected?.config) loadConfig(selected.config);
    setLoading(null);
  }, []);

  const handleDownloadComplete = useCallback(async () => {
    setDownloadMapTiles(null);
    setLoading(null);
    toast.success('Experience downloaded and ready for offline use!');
  }, []);

  const handleDownload = useCallback(async (experience) => {
    if (!experience.offlineEnabled) {
      toast.error('Offline mode is not allowed for this experience');
      return;
    }
    setLoading(experience.id);
    const saved = await saveExperience(experience);
    await downloadAudio(saved.config);
    if (configHasGeoSpots(saved.config)) setDownloadMapTiles(saved);
    else handleDownloadComplete();
  }, []);

  useEffect(() => {
    if (experiences && experiences.length === 1) handleSelect(experiences[0]);
  }, [experiences]);

  return (
    <>
      <SetupStep
        title={<String s="select_experience" />}
        action={<String s="confirm" />}
        actionClick={() => {
          const expLang = selectedExperience?.language;
          if (expLang && availableLanguages.includes(expLang)) {
            // set temp language
            setLanguage(expLang, { temp: true });
          }
          if (p.next) {
            history.push(p.next);
          }
        }}
        disabled={(!config && !selectedExperience?.config) || !!loading}
        form={(
          <Stack spacing={0.5} width="100%">
            {experiences.length === 0 && (
              <>
                <Typography variant="pretitle" mb={2}><String s="no_experience" /></Typography>
                {!user && offline !== null && (
                  <Button
                    color="secondary"
                    variant="text"
                    startIcon={<BackIcon style={{ transform: 'rotate(-180deg)' }} />}
                    onClick={() => offlineMode(null)}
                  >
                    <String s="go_home" />
                  </Button>
                )}
              </>
            )}

            {experiences.map((experience) => {
              const selected = selectedExperience && selectedExperience.id === experience.id;
              return (
                <Stack direction="row" key={experience.id} spacing={0.5}>
                  <Button
                    color="secondary"
                    variant={selected && !loading ? 'contained' : 'outlined'}
                    style={{ paddingLeft: 1, paddingRight: 1 }}
                    fullWidth
                    onClick={() => handleSelect(experience)}
                    disabled={!!loading}
                  >
                    {experience.label}
                  </Button>
                  {!offline && experience.offlineEnabled && (
                    <Button
                      disabled={!!loading}
                      onClick={() => handleDownload(experience)}
                      color="secondary"
                      style={{ paddingLeft: 1, paddingRight: 1 }}
                      variant={experience.config && loading !== experience.id ? 'contained' : 'outlined'}
                    >
                      {loading === experience.id ? (
                        <CircularProgress color="secondary" size="20px" />
                      ) : (
                        <>
                          {experience.config && !experience.fromStorage && <CloudDoneOutlined color="primary" style={{ height: '25px' }} />}
                          {experience.config && experience.fromStorage && <Refresh color="primary" style={{ height: '25px' }} />}
                          {!experience.config && <CloudDownloadOutlinedIcon style={{ height: '25px' }} />}
                        </>
                      )}
                    </Button>
                  )}
                </Stack>
              );
            })}
          </Stack>
        )}
        themeSwitcher
        stepNav
        {...p}
      />
      {!!downloadMapTiles && (
        <DownloadMapTilesHiddenOverlay
          experience={downloadMapTiles}
          loaded={handleDownloadComplete}
        />
      )}
    </>

  );
}

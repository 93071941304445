import React, { useState, useCallback, useMemo } from 'react';
import languages, { default_language } from '../../config/languages';

import alarm_en from '../../assets/audio/en/lock_alert.mp3';
import alarm_it from '../../assets/audio/it/lock_alert.mp3';
import alarm_fr from '../../assets/audio/fr/lock_alert.mp3';
import alarm_es from '../../assets/audio/es/lock_alert.mp3';
import { useStoredState } from '../hooks/useStoredState';
import { LANGUAGE_KEY } from '../utils/localStorage';

const ALARM_SOUNDS = {
  en: alarm_en,
  it: alarm_it,
  es: alarm_es,
  fr: alarm_fr,
};

const LanguageContext = React.createContext(null);

export default function LanguageProvider({ children }) {
  const [storedLanguage, setStoredLanguage] = useStoredState({
    key: LANGUAGE_KEY,
    defaultValue: default_language,
  });

  const [tempLanguage, setTempLanguage] = useState(null);

  const language = useMemo(
    () => tempLanguage ?? storedLanguage,
    [tempLanguage, storedLanguage],
  );

  const setLanguage = useCallback(
    (lang, options = {}) => {
      // if temp, change only local state
      setTempLanguage(lang);
      // if permanent, change also stored state
      if (!options.temp) setStoredLanguage(lang);
    },
    [setTempLanguage, setStoredLanguage],
  );

  const availableLanguages = Object.keys(languages);
  const content = (key, l) => languages[l || language][key];
  const alarmSound = ALARM_SOUNDS[language];

  const contextValue = useMemo(() => ({
    language, setLanguage, availableLanguages, content, alarmSound,
  }), [language, setLanguage, availableLanguages, content, alarmSound]);

  return (
    <LanguageContext.Provider value={contextValue}>
      {children}
    </LanguageContext.Provider>
  );
}

export const useLanguage = () => React.useContext(LanguageContext);

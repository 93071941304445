import { Box } from '@material-ui/core';
import { useMemo, useState } from 'react';
import Layout from '../components/Layout';
import Routes from '../components/Routes';
import Logo from '../components/Logo';
import { useLanguage } from '../contexts/language';
import { SetupExperiences } from '../components/SetupExperiences';
import { SetupHeadphones } from '../components/SetupHeadphones';
import { SetupAudio } from '../components/SetupAudio';
import { SetupGps } from '../components/SetupGps';
import AdminControls from '../components/AdminControls';
import { ADMIN_PANEL_AFTER } from '../../config/config';
import { useExperience } from '../contexts/experience';
import LanguageSelector from '../components/LanguageSelector';

const setupViews = (steps, language) => steps.map(({ component: Component, path }, i) => {
  const next = steps[i + 1] ? steps[i + 1].path : `/experience?lang=${language}`;
  const back = steps[i - 1] ? steps[i - 1].path : null;
  return {
    path,
    render: (props) => (
      <Component
        steps={steps}
        step={i + 1}
        next={next}
        back={back}
        {...props}
      />
    ),
    exact: true,
  };
});

export default function Setup() {
  const { language } = useLanguage();
  const { config, hasGeoSpots, hasManualSpots } = useExperience();

  const views = useMemo(() => {
    const setupSteps = [
      { path: '/setup/1', component: SetupExperiences },
      { path: '/setup/2', component: SetupHeadphones },
      { path: '/setup/3', component: SetupAudio },
    ];
    if (config && hasGeoSpots) {
      setupSteps.push({ path: '/setup/4', component: SetupGps });
    }
    return setupViews(setupSteps, language);
  }, [language, config, hasGeoSpots, hasManualSpots]);

  const [showAdmin, setShowAdmin] = useState(0);

  const openAdminDialog = () => {
    setShowAdmin((prev) => prev + 1);
    setTimeout(() => {
      setShowAdmin((prev) => (prev < ADMIN_PANEL_AFTER ? 0 : prev));
    }, ADMIN_PANEL_AFTER * 1000);
  };

  return (
    <>
      <Layout
        fullscreen
        framed
        top={(
          <>
            <Box
              position="absolute"
              top={0}
              right={0}
              textAlign="right"
            >
              <LanguageSelector />
            </Box>
            <Box mt="4vh" mb="2vh" textAlign="center">
              <Logo onClick={openAdminDialog} />
            </Box>
          </>
        )}
      >
        <Routes views={views} />
      </Layout>
      <AdminControls show={showAdmin >= ADMIN_PANEL_AFTER} onClose={() => setShowAdmin(0)} />
    </>
  );
}

import React from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  Typography,
  Button,
  Grid,
  Stack,
} from '@material-ui/core';
import BackIcon from '@material-ui/icons/ArrowRightAlt';
import Image from './Image';
import ThemeSwitcher from './ThemeSwitcher';
import AppVersion from './AppVersion';

function SetupStep({
  title,
  pretitle,
  text,
  image,
  media,
  form,
  action,
  actionClick,
  auxAction,
  auxActionClick,
  next,
  disabled,
  themeSwitcher,
  stepNav,
  ...p
}) {
  return (
    <Stack justifyContent="center" alignItems="center" width="100%" height="100%">
      <Stack flex="1 1 auto" justifyContent="center" alignItems="center" width="90%" maxWidth="600px" spacing="3vh" mb="3vh" textAlign="center">
        {pretitle && (
          <Typography variant="pretitle">
            {pretitle}
          </Typography>
        )}
        <Typography variant="title">
          {title}
        </Typography>
        {text && (
          <Typography variant="text">
            {text}
          </Typography>
        )}
        <Stack flex="1 1 0" maxHeight="max-content" overflow="auto" alignItems="center" width="100%">
          {media}
          {image && (
            <Image i={image} style={{ height: '15vh' }} />
          )}
          {form}
        </Stack>
        {action && (
          <Box width="100%" mt={3}>
            <Button
              color="primary"
              variant="contained"
              disabled={disabled}
              {...(actionClick ? { onClick: actionClick } : { component: Link, to: next })}
              fullWidth
            >
              {action}
            </Button>
          </Box>
        )}
        {auxAction && auxActionClick && (
          <Box>
            <Button
              color="primary"
              variant="contained"
              mt={2}
              disabled={disabled}
              fullWidth
              onClick={(e) => auxActionClick(e)}
            >
              {auxAction}
            </Button>
          </Box>
        )}
      </Stack>
      <Stack mb="2vh" width="100%" alignItems="center">
        {stepNav && <StepNav {...p} />}
        {themeSwitcher && <ThemeSwitcher />}

        <Stack
          spacing={2}
          direction="row"
          alignItems="center"
          justifyContent="end"
          width="100%"
          pr={1}
        >
          <AppVersion />
        </Stack>
      </Stack>
    </Stack>
  );
}

function StepNav({ step, steps, back }) {
  return (
    <Grid container alignItems="center" height="50px" width="10rem">
      <Grid item xs={3} align="center">
        {back && (
          <Link to={back}>
            <Typography fontSize="1rem" color="textPrimary">
              <BackIcon
                style={{ transform: 'rotate(-180deg)', fontSize: '40px' }}
              />
            </Typography>
          </Link>
        )}
      </Grid>
      <Grid item xs={6} align="center">
        <Typography fontSize="1.5rem" color="textPrimary">
          {step}
          /
          {steps.length}
        </Typography>
      </Grid>
      <Grid item xs={3} />
    </Grid>
  );
}

export default SetupStep;

import Bowser from 'bowser';

export const getTimestamp = (date) => {
  const d = new Date(date);
  return d.getTime() / 1000;
};

export const getDate = (timestamp) => new Date(timestamp * 1000);

export const addDays = (dateInput, days) => {
  const date = new Date(dateInput);
  date.setDate(date.getDate() + days);
  return date;
};

export const daysFromNow = (dateInput) => {
  const date = new Date(dateInput);
  const now = new Date();
  return Math.round((date.getTime() - now.getTime()) / (1000 * 60 * 60 * 24));
};

export const formatTimer = (t) => {
  if (t > 0) {
    const o = new Date(0);
    const p = new Date(t * 1000);
    const array = new Date(p.getTime() - o.getTime())
      .toISOString()
      .split('T')[1]
      .split('Z')[0]
      .split(':');
    let hours;
    try {
      hours = parseInt(array[0], 10);
    } catch {
      // do nothing
    }
    return !hours || hours <= 0 ? `${array[1]}:${array[2].split('.')[0]}` : `${hours}:${array[1]}:${array[2].split('.')[0]}`;
  }
};

const streamToText = async (readableStream) => {
  const reader = readableStream.getReader();
  const decoder = new TextDecoder('utf-8');
  let text = '';
  // eslint-disable-next-line no-constant-condition
  while (true) {
    // eslint-disable-next-line no-await-in-loop
    const { done, value } = await reader.read();
    if (done) {
      break;
    }
    text += decoder.decode(value);
  }
  return text;
};

const textToJson = (text) => {
  try {
    return JSON.parse(text);
  } catch (error) {
    throw new Error(`Failed to parse text as JSON: ${error.message}`);
  }
};

export const processStream = async (readableStream) => {
  try {
    const text = await streamToText(readableStream);
    return textToJson(text);
  } catch (error) {
    console.error('Error:', error);
    return error;
  }
};

export const getDeviceOsAndBrowser = () => {
  const bowser = Bowser.getParser(window.navigator.userAgent);
  const os = bowser.getOSName();
  const browser = bowser.getBrowserName();
  return { os, browser };
};

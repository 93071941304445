import React, {
  useEffect, useRef, useState,
} from 'react';
import {
  Box,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Button,
  Link,
  Stack,
  Typography,
  List,
  ListItem,
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import {
  ExpandMore, LocationDisabled, Refresh, RoomOutlined,
} from '@material-ui/icons';
import String from './String';
import Image from './Image';
import { useExperience } from '../contexts/experience';
import SetupStep from './SetupStep';
import Layout from './Layout';
import { getDeviceOsAndBrowser } from '../utils/utils';
import { HOSTNAME } from '../../config/config';

const { os, browser } = getDeviceOsAndBrowser();

function LocationDisabledOverlay({ hasManualSpots, next }) {
  const softReset = () => {
    window.location = '/';
  };

  return (
    <Backdrop open style={{ zIndex: 5 }}>
      <Layout>
        <Stack py={3} spacing={3} position="relative" width="90%" maxWidth="600px" overflow="scroll">

          <Box
            width="100%"
            textAlign="center"
          >
            <Image
              i="gps"
              style={{
                height: '15vh',
                filter: 'saturate(0)',
              }}
            />
            <Typography
              variant="title"
              fontWeight="bold"
              color="secondary"
            >
              <String s="gps_location_unavailable" />
            </Typography>
          </Box>

          <Typography color="secondary">
            <String s="gps_location_unavailable_desc" />
          </Typography>

          <Accordion>
            <AccordionSummary
              aria-controls="instructions-content"
              expandIcon={<ExpandMore />}
            >
              <Typography fontWeight="bold">
                <String s="read_instructions" />
              </Typography>
            </AccordionSummary>

            <AccordionDetails>
              {os === 'Android' && (
                <Stack spacing={1}>
                  <Typography variant="pretitle">
                    <String s="gps_location_open_phone_settings" />
                  </Typography>
                  <List sx={{ listStyleType: 'disc', pl: 4 }}>
                    <ListItem sx={{ display: 'list-item' }}>
                      <Typography>
                        <String s="gps_location_android_1" />
                      </Typography>
                    </ListItem>
                    <ListItem sx={{ display: 'list-item' }}>
                      <Typography>
                        <String s="gps_location_android_2" />
                      </Typography>
                    </ListItem>
                    <ListItem sx={{ display: 'list-item' }}>
                      <Typography>
                        <String s="gps_location_android_2" />
                      </Typography>
                    </ListItem>
                    <ListItem sx={{ display: 'list-item' }}>
                      <Typography>
                        <String s="gps_location_android_3" />
                      </Typography>
                    </ListItem>
                    <ListItem sx={{ display: 'list-item' }}>
                      <Typography>
                        <String s="gps_location_android_4" />
                        {' '}
                        {browser === 'Chrome' && 'Chrome'}
                        {browser === 'Safari' && 'Safari'}
                        {browser === undefined && <String s="gps_location_browser_in_use" />}
                        {' '}
                        <String s="gps_location_android_5" />
                      </Typography>
                    </ListItem>
                    <ListItem sx={{ display: 'list-item' }}>
                      <Typography>
                        <String s="gps_location_android_6" />
                      </Typography>
                    </ListItem>
                  </List>
                </Stack>
              )}

              {os === 'iOS' && (
                <Stack spacing={1}>
                  <Typography variant="pretitle">
                    <String s="gps_location_open_phone_settings" />
                  </Typography>

                  <List sx={{ listStyleType: 'disc', pl: 4 }}>
                    <ListItem sx={{ display: 'list-item' }}>
                      <Typography>
                        <String s="gps_location_ios_1" />
                      </Typography>
                    </ListItem>
                    <ListItem sx={{ display: 'list-item' }}>
                      <Typography>
                        <String s="gps_location_ios_2" />
                      </Typography>
                    </ListItem>
                    {browser === 'Chrome' && (
                      <ListItem sx={{ display: 'list-item' }}>
                        <Typography>
                          <String s="gps_location_ios_2_1_chrome" />
                        </Typography>
                      </ListItem>
                    )}
                    {browser === 'Safari' && (
                      <ListItem sx={{ display: 'list-item' }}>
                        <Typography>
                          <String s="gps_location_ios_2_1_safari" />
                        </Typography>
                      </ListItem>
                    )}
                    <ListItem sx={{ display: 'list-item' }}>
                      <Typography>
                        <String s="gps_location_ios_3" />
                      </Typography>
                    </ListItem>
                    <ListItem sx={{ display: 'list-item' }}>
                      <Typography>
                        <String s="gps_location_ios_4" />
                        {' '}
                        {browser === 'Chrome' && 'Chrome'}
                        {browser === 'Safari' && 'Safari'}
                        {browser === undefined && <String s="gps_location_browser_in_use" />}
                      </Typography>
                    </ListItem>
                    <ListItem sx={{ display: 'list-item' }}>
                      <Typography>
                        <String s="gps_location_ios_5" />
                      </Typography>
                    </ListItem>
                    <ListItem sx={{ display: 'list-item' }}>
                      <Typography>
                        <String s="gps_location_ios_6" />
                      </Typography>
                    </ListItem>
                  </List>
                </Stack>
              )}

              {browser === 'Chrome' && (
                <Stack spacing={1}>
                  <Typography variant="pretitle">
                    <String s="gps_location_chrome_1" />
                  </Typography>

                  <List sx={{ listStyleType: 'disc', pl: 4 }}>

                    <ListItem sx={{ display: 'list-item' }}>
                      <Typography>
                        <String s="gps_location_chrome_2" />
                        {' '}
                        <Link href={HOSTNAME} target="_blank">{HOSTNAME}</Link>
                      </Typography>
                    </ListItem>

                    <ListItem sx={{ display: 'list-item' }}>
                      <Typography>
                        <String s="gps_location_chrome_3" />
                      </Typography>
                    </ListItem>

                    <ListItem sx={{ display: 'list-item' }}>
                      <Typography>
                        <String s="gps_location_chrome_4" />
                      </Typography>
                    </ListItem>

                    <ListItem sx={{ display: 'list-item' }}>
                      <Typography>
                        <String s="gps_location_chrome_5" />
                      </Typography>
                    </ListItem>
                  </List>
                </Stack>
              )}

              {browser === 'Safari' && (
                <Stack spacing={1}>
                  <Typography variant="pretitle">
                    <String s="gps_location_safari_1" />
                  </Typography>

                  <List sx={{ listStyleType: 'disc', pl: 4 }}>
                    <ListItem sx={{ display: 'list-item' }}>
                      <Typography>
                        <String s="gps_location_safari_2" />
                        {' '}
                        <Link href={HOSTNAME} target="_blank">{HOSTNAME}</Link>
                      </Typography>
                    </ListItem>

                    <ListItem sx={{ display: 'list-item' }}>
                      <Typography>
                        <String s="gps_location_safari_3" />
                      </Typography>
                    </ListItem>

                    <ListItem sx={{ display: 'list-item' }}>
                      <Typography>
                        <String s="gps_location_safari_4" />
                      </Typography>
                    </ListItem>

                    <ListItem sx={{ display: 'list-item' }}>
                      <Typography>
                        <String s="gps_location_safari_5" />
                      </Typography>
                    </ListItem>

                    <ListItem sx={{ display: 'list-item' }}>
                      <Typography>
                        <String s="gps_location_safari_6" />
                      </Typography>
                    </ListItem>
                  </List>
                </Stack>
              )}

              {!browser && (
                <Stack spacing={1}>
                  <Typography variant="pretitle">
                    <String s="gps_location_browser_1" />
                  </Typography>

                  <List sx={{ listStyleType: 'disc', pl: 4 }}>
                    <ListItem sx={{ display: 'list-item' }}>
                      <String s="gps_location_browser_2" />
                      {' '}
                      <Link href={HOSTNAME} target="_blank">{HOSTNAME}</Link>
                    </ListItem>
                    <ListItem sx={{ display: 'list-item' }}>
                      <Typography>
                        <String s="gps_location_browser_3" />
                      </Typography>
                    </ListItem>
                    <ListItem sx={{ display: 'list-item' }}>
                      <Typography>
                        <String s="gps_location_browser_4" />
                      </Typography>
                    </ListItem>
                  </List>
                </Stack>
              )}
            </AccordionDetails>
          </Accordion>

          <Button
            color="secondary"
            variant="contained"
            fullWidth
            onClick={softReset}
            startIcon={<Refresh />}
          >
            <String s="refresh" />
          </Button>

          {hasManualSpots && next && (
            <Stack spacing={1}>
              <Button
                color="secondary"
                variant="outlined"
                startIcon={<LocationDisabled />}
                component={RouterLink}
                to={next}
              >
                <String s="go_without_gps" />
              </Button>
              <Typography
                fontSize="0.8rem"
                fontStyle="italic"
                fontWeight="bold"
              >
                <String s="go_without_gps_warning" />
              </Typography>
            </Stack>
          )}
        </Stack>
      </Layout>
    </Backdrop>
  );
}

function GpsStatus({ quality }) {
  if (quality === 1) return <Typography color="red" variant="pretitle" mb={0}><String s="gps_quality_bad" /></Typography>;
  if (quality === 2) return <Typography color="#CAA53D" variant="pretitle" mb={0}><String s="gps_quality_average" /></Typography>;
  if (quality === 3) return <Typography color="green" variant="pretitle" mb={0}><String s="gps_quality_good" /></Typography>;
  return <Typography variant="pretitle" mb={0}><String s="wait" /></Typography>;
}

export function SetupGps(p) {
  const { config, hasManualSpots } = useExperience();

  const [enabled, setEnabled] = useState(true);
  const [gpsStatus, setGpsStatus] = useState({ quality: 0, accuracy: undefined, error: undefined });
  const geoWatchSub = useRef();

  const gpsConfig = {
    enableHighAccuracy: config?.geo?.options?.enableHighAccuracy || true,
    maximumAge: config?.geo?.options?.maximumAge || 30000,
    timeout: config?.geo?.options?.timeout || 10000,
  };

  const getGpsQuality = (accuracy) => {
    // bypass gps accuracy check
    if (window.gpsAccuracyCheckForced) return 3;

    // gps quality index 0: no position, 1: bad, 2: acceptable, 3: good
    if (accuracy <= (config?.geo?.options.accuracy || 50) * 0.2) return 3;
    if (accuracy <= (config?.geo?.options.accuracy || 50)) return 2;
    if (accuracy) return 1;
    return 0;
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(() => {
      setEnabled(true);
    }, (error) => {
      console.error('initial GPS accuracy check', error);
      if ([error.POSITION_UNAVAILABLE, error.PERMISSION_DENIED].includes(error.code)) {
        setEnabled(false);
      } else setEnabled(true);
    }, gpsConfig);
  }, []);

  useEffect(() => {
    if (enabled) {
      // gps is auth and enabled

      setGpsStatus({
        error: undefined,
        accuracy: undefined,
        quality: 0,
      });

      // subscribe location updates
      geoWatchSub.current = navigator.geolocation.watchPosition((position) => {
        console.info('GPS accuracy check', position);
        setGpsStatus({
          error: undefined,
          accuracy: position.coords.accuracy,
          quality: getGpsQuality(position?.coords?.accuracy),
        });
      }, (error) => {
        console.error('GPS accuracy check', error);
        // avoid registering timeout errors
        if (error.code !== error.TIMEOUT) {
          setGpsStatus({
            error: error.message,
            accuracy: undefined,
            quality: 0,
          });
        }
      }, gpsConfig);
    } else {
      // gps is unauth or disabled
      setGpsStatus({ quality: 0, accuracy: undefined, error: 'User denied geolocation or location services unavailable' });
      if (geoWatchSub.current) navigator.geolocation.clearWatch(geoWatchSub.current);
    }

    return () => geoWatchSub.current && navigator.geolocation.clearWatch(geoWatchSub.current);
  }, [enabled]);

  // override gps controls
  const forceGpsAccuracyCheck = () => {
    setGpsStatus({ error: undefined, accuracy: 5, quality: 3 });
    window.gpsAccuracyCheckForced = true;
  };
  window.forceGpsAccuracyCheck = forceGpsAccuracyCheck;

  const checkOk = !gpsStatus.error
    && (gpsStatus.quality > 1 || (hasManualSpots && gpsStatus.quality > 0));

  return (
    <>
      <SetupStep
        pretitle={<String s="wait_location" />}
        title={(
          <>
            <String s="gps" />
            <br />
            <RoomOutlined />
          </>
        )}
        action={<String s="continue" />}
        disabled={!checkOk}
        form={gpsStatus.error ? (
          <Stack spacing={1} textAlign="center">
            <Typography color="red" variant="pretitle"><String s="gps_error" /></Typography>
            <Typography style={{ fontSize: '10px' }}>
              {gpsStatus.error}
            </Typography>
          </Stack>
        ) : (
          <GpsStatus quality={gpsStatus.quality} />
        )}
        themeSwitcher
        stepNav
        {...p}
      />
      {!enabled && (
        <LocationDisabledOverlay
          hasManualSpots={hasManualSpots}
          next={p.next}
        />
      )}
    </>
  );
}

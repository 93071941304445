import React from 'react';
import {
  Box, Button, LinearProgress,
} from '@material-ui/core';
import PlayIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import { PiMusicNotes, PiMusicNote } from 'react-icons/pi';
import { motion } from 'framer-motion';
import PlayerBottomSheet from './PlayerBottomSheet';
import { useTheme } from '../contexts/theme';
import { useExperience } from '../contexts/experience';
import String from './String';

const AnimatedSpan = ({ startAnimation, children }) => {
  if (!startAnimation) {
    return (
      <span
        style={{
          display: 'inline-flex',
        }}
      >
        {children}
      </span>
    );
  }

  return (
    <motion.span
      style={{
        display: 'inline-flex',
      }}
      animate={{
        scale: [1, 1.3, 1, 1.3, 1],
        rotate: [0, 360, 360, 360, 0],
      }}
      transition={{
        repeat: Infinity,
        duration: 3,
        ease: 'backInOut',
      }}
    >
      {children}
    </motion.span>
  );
};

const LinearProgresBar = ({ audio }) => {
  const [seek, setSeek] = React.useState(0);

  // eslint-disable-next-line
  const duration = audio?._duration;
  const progress = (seek / duration) * 100;

  React.useEffect(() => {
    if (!audio) return;
    const seekInterval = setInterval(() => {
      try {
        const currentSeek = audio.seek();
        setSeek(currentSeek);
      } catch (error) {
        // do nothing
      }
    }, 100);
    return () => clearInterval(seekInterval);
  }, [audio, duration]);

  return (
    <Box width="100%">
      <LinearProgress
        color="secondary"
        variant="determinate"
        value={progress}
      />
    </Box>
  );
};

const PlayerOnMap = () => {
  const [openSheet, setOpenSheet] = React.useState(false);
  const { styles } = useTheme();
  const primary = styles.palette.primary.main;
  const { isActive, active } = useExperience();
  const singleSpotActive = active?.length === 1;
  const someIsPlaying = active?.some((spot) => spot.audio.playing());
  const isManualActive = singleSpotActive && Boolean(active[0]?.spot?.code);

  React.useEffect(() => {
    if (!isActive) {
      setOpenSheet(false);
    }

    if (isActive && isManualActive) {
      setOpenSheet(true);
    }
  }, [isActive, isManualActive]);

  return (
    <>
      <PlayerBottomSheet
        openSheet={openSheet}
        setOpenSheet={setOpenSheet}
      />
      <Box
        position="absolute"
        width="100%"
        bgcolor={primary}
        bottom={0}
        borderRadius="5px 5px 0 0"
        zIndex={999}
        style={{
          opacity: isActive ? 1 : 0,
          pointerEvents: isActive ? 'all' : 'none',
          transform: isActive
            ? 'scale(1) translate(0px, 0px)'
            : 'scale(0.8) translate(0px, 100px)',
          transition: 'all .5s ease-in-out',
        }}
      >
        <Button
          color="primary"
          variant="contained"
          fullWidth
          onClick={() => setOpenSheet(true)}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingLeft: '1rem',
            paddingRight: '1rem',
          }}
        >
          <span
            style={{
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              gap: 4,
            }}
          >
            <AnimatedSpan startAnimation={someIsPlaying}>
              {singleSpotActive
                ? <PiMusicNote style={{ fontSize: '1.5rem' }} />
                : <PiMusicNotes style={{ fontSize: '1.5rem' }} />}
            </AnimatedSpan>

            {active?.length > 0 && (
              singleSpotActive ? (`${active[0].spot.label}`) : <String s="multiple_agami_active" />
            )}
          </span>

          {someIsPlaying
            ? <PauseIcon style={{ fontSize: '2rem' }} />
            : <PlayIcon style={{ fontSize: '2rem' }} />}
        </Button>

        {singleSpotActive && (
          <LinearProgresBar audio={active[0]?.audio} />
        )}
      </Box>
    </>
  );
};

export default PlayerOnMap;

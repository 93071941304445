import { createClient } from '@supabase/supabase-js';
import { SUPABASE_PUBLIC_KEY, SUPABASE_URL } from '../../config/config';
import { processStream } from './utils';

// **** CLIENT ****
export const supabase = createClient(SUPABASE_URL, SUPABASE_PUBLIC_KEY);

export const signInWithEmailAndPassword = async ({ email, password }) => {
  const { data, error } = await supabase.auth.signInWithPassword({
    email,
    password,
  });
  return { data, error };
};

export const signInWithEmail = async ({ email, redirectTo }) => {
  const { data, error } = await supabase.auth.signInWithOtp({
    email,
    options: {
      emailRedirectTo: redirectTo,
      shouldCreateUser: false,
    },
  });
  return { data, error };
};

export const signOut = async () => {
  const { error } = await supabase.auth.signOut();
  return { error };
};

export const getSupabaseSession = async () => {
  const { data, error } = await supabase.auth.getSession();
  return { data: data.session, error };
};

export const getUserProfile = async () => {
  // returns only current user profile because of RLS policy
  const { data, error } = await supabase
    .from('profiles')
    .select('*, roles (role_name)')
    .single(1);
  return { data, error };
};

export const getUserExperiences = async ({ userId }) => {
  const { data, error } = await supabase
    .from('user_experiences')
    .select(`
      *,
      experiences!inner(
        is_active,
        name,
        owner_id,
        created_at,
        is_default,
        main_language,
        profiles!experiences_owner_id_fkey(name, surname)
      )
    `)
    .eq('user_id', userId)
    .not('is_enabled', 'is', false)
    .eq('experiences.is_canceled', false);

  return { data, error };
};

export const getGeoXpConfig = async ({ experienceId }) => {
  if (!experienceId) {
    console.error('getGeoXpConfig - no experienceId provided');
    return { error: 'No experienceId provided' };
  }

  const { data, error } = await supabase
    .functions
    .invoke('get-geoxp-config', {
      body: JSON.stringify({ experienceId }),
    });

  if (!error) {
    return { data };
  }

  const { body } = error.context;

  if (!body) {
    return { data, error };
  }

  const parsedError = await processStream(body);

  if (parsedError) {
    return {
      data,
      error: parsedError.error ?? parsedError,
    };
  }

  return { data, error };
};

export const redeemExpCode = async ({
  code,
  email,
  redirectTo,
  anonymousAccess,
  anonymousAccessUserId,
}) => {
  const { data, error } = await supabase
    .functions
    .invoke('access-code-redeem', {
      body: JSON.stringify({
        code,
        email,
        redirectTo,
        anonymousAccess,
        anonymousAccessUserId,
      }),
    });

  if (!error) {
    return { data };
  }

  const { body } = error.context;

  if (!body) {
    return { data, error };
  }

  const parsedError = await processStream(body);

  if (parsedError) {
    return {
      data,
      error: parsedError.error ?? parsedError,
    };
  }

  return { data, error };
};
